<nav id="navbar" class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" [routerLink]="['/']">
    <a class="navbar-left"><img class="pull-left" style="max-width:40px" src="assets/rhizomik.svg"/></a>
    <a class="navbar-left"><img class="pull-left" style="max-height:40px" src="assets/copyrightly.svg"/></a>
  </a>
  <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed" aria-controls="navbarContent" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarContent" [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" [routerLink]="['/about']">About</a>
      </li>
      <li class="nav-item dropdown" ngbDropdown>
        <a class="nav-link dropdown-toggle" id="ManifestationsDropdown" role="button" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>Authorship</a>
        <div class="dropdown-menu" aria-labelledby="ManifestationsDropdown" ngbDropdownMenu>
          <a class="dropdown-item" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }"
             [routerLink]="['/list']"><span class="fas fa-list"></span> List All</a>
          <a class="dropdown-item" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }"
             [routerLink]="['/register']" [class.disabled]="!isLoggedIn()">
            <span class="fas fa-gavel"></span> Register</a>
        </div>
      </li>
      <li class="nav-item dropdown" ngbDropdown>
        <a class="nav-link dropdown-toggle" id="ComplaintsDropdown" role="button" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>Search</a>
        <div class="dropdown-menu" aria-labelledby="ComplaintsDropdown" ngbDropdownMenu>
          <a class="dropdown-item" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }"
             [routerLink]="['/creators']"><span class="fas fa-search"></span> Creator</a>
          <a class="dropdown-item" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }"
             [routerLink]="['/search']"><span class="fas fa-search"></span> Authorship Claim</a>
        </div>
      </li>
      <li class="nav-item" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" [routerLink]="['/token']">CLY Token</a>
      </li>
    </ul>
    <app-authentication-navbar-loggedout *ngIf="!isLoggedIn()" (login)="login()">
    </app-authentication-navbar-loggedout>
    <app-authentication-navbar-loggedin *ngIf="isLoggedIn()" (logout)="logout()" (currentUser)="setCurrentUser($event)">
    </app-authentication-navbar-loggedin>
  </div>
</nav>
