<ul class="navbar-nav">
  <li class="nav-item">
    <span class="nav-link">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend">
          <span class="input-group-text" id="network-name">
            <span class="fab fa-ethereum"></span>{{currentNetwork}}</span>
        </div>
        <input *ngIf="accounts.length === 0"  id="connecting" name="connecting" type="text" readonly
               class="form-control" value="Connecting...">
        <input *ngIf="accounts.length >= 1"  id="account" name="account" type="text" class="form-control"
               readonly #account (focus)="account.blur()"
               title="{{accountId}}" [ngModel]="accountName" (click)="currentUserProfile()">
        <select *ngIf="accounts.length > 1" id="accounts" name="accounts" class="custom-select" title="{{accountId}}"
                [(ngModel)]="accountId" (ngModelChange)="onChange($event)">
          <option *ngFor="let account of accounts; let i = index" [ngValue]="account" title="{{account}}">
            {{accountsNames[i]}}</option>
        </select>
        <div class="input-group-append" >
          <button id="refresh-accounts" class="btn btn-secondary" #refresh_accounts
                  (focus)="refresh_accounts.blur()"  (click)="refreshAccounts()"><span class="fas fa-sync-alt"></span>
          </button>
        </div>
        <div class="input-group-append" >
          <button id="logout" class="btn btn-dark" #logout
                  (focus)="logout.blur()"  (click)="disconnect()"><span class="fas fa-sign-out-alt"></span>
          </button>
        </div>
      </div>
    </span>
  </li>
</ul>
