
<div class="card mb-4">
  <div class="card-block row m-1">
    <h5 class="card-title col-md-12 p-1"><span class="fas fa-upload"></span> Uploadable Evidence {{evidenceIndex}}</h5>
    <div class="col-lg-6 p-1 mb-1">
      <h6 class="card-subtitle text-muted">Uploaded Content</h6>
      <a class="card-text" href="https://gateway.ipfs.io/ipfs/{{evidence?.id}}" target="_blank">
        {{evidence?.id}}</a>
    </div>
    <div class="col-lg-2 p-1 mb-1">
      <h6 class="card-subtitle text-muted">By</h6>
      <a class="card-text" title="{{evidence?.evidencer}}" [routerLink]="['/creators', evidence?.evidencer]">
        {{evidence?.evidencer | slice:0:6}}...{{evidence?.evidencer | slice:-4}}
      </a>
    </div>
    <div class="col-lg-4 p-1 mb-1">
      <h6 class="card-subtitle text-muted">Registered</h6>
      <a class="card-text" href="https://goerli.etherscan.io/tx/{{evidence?.transaction}}" target="_blank">
        {{evidence?.creationTime | date:'medium'}}</a>
    </div>
    <div class="col-lg-6 p-1 mb-1">
      <h6 class="card-subtitle text-muted">For Manifestation</h6>
      <a class="card-text" [routerLink]="['/manifestations', evidence?.evidenced]">
        {{evidence?.evidenced}}</a>
    </div>
  </div>
</div>
