import { Injectable, NgZone } from '@angular/core';
import { Web3Service } from '../util/web3.service';
import { Observable } from 'rxjs/internal/Observable';
import { ReplaySubject } from 'rxjs';
import { YouTubeEvidence } from './youtubeEvidence';
import { YouTubeEvidenceEvent } from './youtube-evidence-event';
import { Event } from '../util/event';
import { VerificationRequest } from './verification-request';

declare const require: any;
const evidenceContract = require('../../assets/contracts/YouTubeEvidence.json');
const evidenceMockContract = require('../../assets/contracts/YouTubeEvidenceMock.json');
const manifestations = require('../../assets/contracts/Manifestations.json');

@Injectable({
  providedIn: 'root'
})
export class YouTubeEvidenceContractService {

  private deployedContract = new ReplaySubject<any>(1);
  private manifestationsAddress = '';
  private watching = true; // Default try to watch events

  constructor(private web3Service: Web3Service,
              private ngZone: NgZone) {
    this.web3Service.networkId.subscribe((networkId: number) => {
      if (evidenceContract.networks[networkId]) {
        const deployedAddress = evidenceContract.networks[networkId].address;
        this.manifestationsAddress = manifestations.networks[networkId].address;
        this.deployedContract.next(
          new this.web3Service.web3.eth.Contract(evidenceContract.abi, deployedAddress));
      } else if (evidenceMockContract.networks[networkId]) {
        const deployedAddress = evidenceMockContract.networks[networkId].address;
        this.manifestationsAddress = manifestations.networks[networkId].address;
        this.deployedContract.next(
          new this.web3Service.web3.eth.Contract(evidenceMockContract.abi, deployedAddress));
      } else {
        this.deployedContract.error(new Error('YouTubeEvidence contract ' +
          'not found in current network with id ' + networkId));
      }
    });
  }

  public addEvidence(evidence: YouTubeEvidence, account: string): Observable<string | VerificationRequest> {
    return new Observable((observer) => {
      this.deployedContract.subscribe(contract => {
        const method = contract.methods.check(this.manifestationsAddress, evidence.evidenced, evidence.videoId);
        const options = { from: account };
        this.web3Service.estimateGas(method,options).then(optionsWithGas => method.send(optionsWithGas)
          .on('transactionHash', (hash: string) =>
            this.ngZone.run(() => observer.next(hash) ))
          .on('receipt', (receipt: any) => {
            const evidenceEvent = new VerificationRequest(receipt.events.VerificationRequest);
            this.web3Service.getBlockDate(receipt.events.VerificationRequest.blockNumber)
            .subscribe(date => {
              this.ngZone.run(() => {
                evidenceEvent.when = date;
                evidenceEvent.watching = this.watching;
                observer.next(evidenceEvent);
                observer.complete();
              });
            });
          })
          .on('error', (error: string) => {
            console.error(error);
            this.ngZone.run(() => {
              observer.error(new Error('Error registering evidence, see log for details'));
              observer.complete();
            });
          })
        );
      }, error => this.ngZone.run(() => { observer.error(error); observer.complete(); }));
      return { unsubscribe: () => {} };
    });
  }

  public watchEvidenceEvents(account: string): Observable<Event> {
    return new Observable((observer) => {
      this.deployedContract.subscribe(contract => {
        contract.events.YouTubeEvidenceEvent({ filter: { evidencer: account }, fromBlock: 'latest' },
          (error: string, event: any) => {
            if (error) {
              this.watching = false; // Not possible to watch for events
              this.ngZone.run(() => {
                observer.error(new Error(error.toString()));
              });
            } else {
              const evidenceEvent = new YouTubeEvidenceEvent(event);
              this.web3Service.getBlockDate(event.blockNumber)
              .subscribe(date => {
                this.ngZone.run(() => {
                  evidenceEvent.when = date;
                  observer.next(evidenceEvent);
                });
              });
            }
          });
      }, error => this.ngZone.run(() => { observer.error(error); observer.complete(); }));
      return { unsubscribe: () => {} };
    });
  }
}
