<h5><span class="fa fa-gavel"></span> Add YouTube Authorship Evidence</h5>
<p class="lead">Link owned YouTube video to support the authorship claim on the manifestation</p>

<form id="evidence-form" (ngSubmit)="addEvidence(evidenceForm)" #evidenceForm="ngForm">
  <fieldset>

    <!-- Evidencer input -->
    <div class="form-group" [class.was-validated]="inputEvidencer.dirty || inputEvidencer.touched">
      <label class="control-label" for="inputEvidencer">Evidencer*</label>
      <input id="inputEvidencer" name="inputEvidencer" type="text" class="form-control" disabled
             [ngModel]="account" #inputEvidencer="ngModel">
    </div>

    <!-- Manifestation hash input -->
    <div class="form-group" [class.was-validated]="inputEvidenced.dirty || inputEvidenced.touched">
      <label class="control-label" for="inputEvidenced">Manifestation*</label>
      <input id="inputEvidenced" name="inputEvidenced" type="text" class="form-control" disabled
             [ngModel]="youtubeEvidence.evidenced" #inputEvidenced="ngModel">
    </div>

    <!-- VideoId input -->
    <div class="form-group" [class.was-validated]="inputVideoId.dirty || inputVideoId.touched">
      <label class="control-label" for="inputVideoId">YouTube Video Identifier*</label>
      <input id="inputVideoId" name="inputVideoId" type="text" class="form-control"
             required [(ngModel)]="youtubeEvidence.videoId"
             #inputVideoId="ngModel" placeholder="Video Id">
      <div class="invalid-feedback">A YouTube video identifier is required</div>
    </div>

    <!-- YouTube Link input -->
    <div class="form-group">
      <div class="form-check">
        <input id="linkedFromYouTube" name="linkedFromYouTube" type="checkbox"
               class="form-check-input" [(ngModel)]="linkedFromYouTube">
        <label class="control-label" for="linkedFromYouTube">The <b>description of the YouTube video
          includes a link to this manifestation</b>, as a way to proof that I'm its owner.<br>
          <small>For instance, you can copy the following fragment and paste it as part of the video
            description in YouTube. Please, do this prior to registering this evidence.
            Otherwise, the evidence will not be valid and the validation cost lost.
          </small>
        </label>
      </div>
      <textarea id="inputYouTubeLink" class="form-control"
        rows="2">Registered in CopyrightLY: https://copyrightly.rhizomik.net/manifestations/{{youtubeEvidence.evidenced}}</textarea>
    </div>

    <!-- Button -->
    <div class="form-group">
      <button id="register" type="submit" class="btn btn-success pull-right"
              [disabled]="!evidenceForm.form.valid || !inputVideoId.value || !linkedFromYouTube">Register</button>
      <button id="cancel" type="button" class="btn btn-danger pull-right ml-2"
              (click)="cancelEvidence()">Cancel</button>
    </div>

  </fieldset>
</form>
