<div *ngIf="accountId">

  <div class="card">
    <img class="card-img-top" height="50px" [src]="getBackgroundURL(profile?.background)"/>
    <div class="avatar">
      <img class="avatar-image" [style.backgroundImage]="'url('+ getAvatarURL(profile?.image) +')'"/>
    </div>
    <div class="card-body">
      <h5 class="card-title">{{profile?.name}}</h5>
      <h6 class="card-subtitle mb-4 text-muted">Account:
        <a href="https://goerli.etherscan.io/address/{{accountId}}" target="_blank">{{accountId}}</a>
      </h6>
      <div *ngIf="accounts?.length" class="row mt-3">
        <p class="card-text text-muted col-lg-2 mb-1">Linked accounts:</p>
        <a class="card-text col-lg-2" *ngFor="let account of accounts" title="{{account}}"
           [routerLink]="['/creators', account]">
          {{account | slice:0:6}}...{{account | slice:-4}}
        </a>
      </div>
      <div *ngIf="social" class="row mt-3">
        <p class="card-text text-muted col-lg-2 mb-1">Social accounts:</p>
        <span *ngFor="let account of social.accounts">
          <a class="card-text col-lg-2" *ngIf="account.host === 'twitter.com'"
             href="https://twitter.com/{{account.id}}"><span class="fab fa-twitter"></span> {{account.id}}</a>
        </span>
      </div>
    </div>
    <div *ngIf="editable()" class="card-footer">
      <a type="button" class="btn btn-sm btn-success fa-pull-right ml-2" target="_blank"
         href="https://clay.self.id/{{this.accountId}}@eip155:4">Edit Profile</a>
    </div>
  </div>

  <h4 class="mt-3">Authorship Claims</h4>

  <app-manifestations-list [manifester]="accountId"></app-manifestations-list>

  <h4><span class="fas fa-copyright logo-icon"></span>LY Stakes</h4>

  <p>Total stake: {{account.staked}} <b><span class="fas fa-copyright logo-icon"></span>LY</b></p>

  <div class="card mb-1" *ngFor="let stake of account.stakes">
    <div class="card-block row m-1">
      <div class="col-md-8 p-1 mb-1">
        <h6 class="card-subtitle text-muted">Manifestation</h6>
        <a class="card-text" [routerLink]="['/manifestations', stake.hash]">
          {{stake.title}}
        </a>
      </div>
      <div class="col-md p-1 mb-1">
        <h6 class="card-subtitle text-muted">Staked</h6>
        <p class="card-text">{{stake.staked}} <b><span class="fas fa-copyright logo-icon"></span>LY</b></p>
      </div>
    </div>
  </div>

</div>

<div *ngIf="!accountId" class="text-center">
  <button class="btn btn-info" (click)="back()">Back</button>
</div>
