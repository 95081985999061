// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  thegraph: 'https://api.studio.thegraph.com/query/1303/copyrightly-goerli/0.0.2',
  thegraphlocal: 'http://localhost:8000/subgraphs/name/copyrightly',
  infuraToken: 'f8e436de7dd243f5a6192aadc8cc5b44',
  infuraIPFSId: '2JNgNOMr6OYYbnB5rzFSO8Z4bM4',
  infuraIPFSSecret: '8372234a1fdb118c40c79b7fa09d0f09'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
