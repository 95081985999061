<div *ngIf="!notFound">
  <div class="row">
    <h3 class="col">Authorship Claim</h3>
    <div class="col-1">
      <button id="refresh-manifestations" class="btn-sm btn-outline-dark fa-pull-right" (click)="refresh()">
        <span class="fas fa-sync-alt"></span>
      </button>
    </div>
  </div>

  <div class="row">
    <p class="col">Based on a <b>manifestation</b>, the creation content uploaded to decentralised storage
      and its content <b>hash</b> registered on-chain by the claimer.</p>
  </div>

  <div id="list">
  <div class="card mb-1">
    <div class="card-block row m-1">
      <h5 class="card-title col-md-12 p-1"><span class="fas fa-gavel"></span> {{manifestation?.title}}</h5>
      <div class="col-lg-6 p-1 mb-1">
        <h6 class="card-subtitle text-muted">Hash</h6>
        <a class="card-text" href="https://gateway.ipfs.io/ipfs/{{manifestation?.hash}}"
           target="_blank">{{manifestation?.hash}}</a>
      </div>
      <div class="col-lg-2 p-1 mb-1">
        <h6 class="card-subtitle text-muted">Creator</h6>
        <a class="card-text" *ngFor="let author of manifestation?.authors" title="{{author}}"
           [routerLink]="['/creators', author]">
          {{author | slice:0:6}}...{{author | slice:-4}}
        </a>
      </div>
      <div class="col-lg-3 p-1 mb-1">
        <h6 class="card-subtitle text-muted">Registered</h6>
        <a class="card-text" href="https://goerli.etherscan.io/tx/{{manifestation?.transaction}}" target="_blank">
          {{manifestation?.creationTime | date:'medium'}}</a>
      </div>
      <div class="col-lg-1 p-1 mb-1">
        <h6 class="card-subtitle text-muted">Evidence</h6>
        <p class="card-text">{{manifestation?.evidenceCount}}</p>
      </div>
    </div>
    <div class="card-footer">
      <div class="form-group row m-1">
        <div class="input-group input-group-sm">
          <input id="inputStaked" name="inputStaked" type="number" class="form-control" readonly #inputStaked="ngModel"
                 [ngModel]="manifestation.getStaked()">
          <div class="input-group-append">
          <span class="input-group-text">
            <span class="fas fa-copyright logo-icon"></span>LY Staked</span>
          </div>
          <button type="button" class="btn btn-sm btn-success ml-2" [disabled]="staking || addingEvidence()"
                  (click)="addStake()">Add</button>
          <button type="button" class="btn btn-sm btn-danger ml-2" [disabled]="staking || addingEvidence()"
                  (click)="removeStake()">Remove</button>
        </div>
      </div>
    </div>
  </div>
  </div>

  <app-manifestation-stake *ngIf="staking && !addingEvidence()" [type]="type" [manifestation]="manifestation"
                           [hidden]="hidAddStake" (cancel)="staking = false" (done)="addedStake($event)">
  </app-manifestation-stake>

  <div *ngIf="manifestation.getStaked() === '0'" class="card mb-4">
    <div class="card-block row m-1">
      <h5 class="card-title col-md-12 p-1">No stake on this manifestation yet</h5>
      <p class="card-text col-md-12 p-1 alert-danger">Warning! This manifestation can be <b>overwritten</b>, if no
        CLY is staked on it, after {{manifestation?.expiryTime | date:'medium'}}</p>
    </div>
  </div>

  <button class="btn btn-secondary mb-4" (click)="back()">Back</button>

  <h3>Authorship Evidence</h3>

  <div class="row">
    <p class="col">Evidence supporting the authorship claim. Any content <b>uploaded</b> to decentralised storage or
      existing <b>YouTube</b> video.</p>
  </div>

  <button *ngIf="!addingEvidence()" type="button" class="btn btn-success mb-4"
          (click)="addUploadableEvidence()">Add Uploadable Evidence</button>

  <button *ngIf="!addingEvidence()" type="button" class="btn btn-success mb-4 ml-2"
          (click)="addYouTubeEvidence()">Add YouTube Evidence</button>

  <app-upload-evidence *ngIf="addingUploadableEvidence" [manifestation]="manifestation" [hidden]="hidUploadEvidence"
                       (cancel)="addingUploadableEvidence = false" (done)="hidUploadEvidence = true"
                       (evidence)="addedUploadEvidence($event.what)">
  </app-upload-evidence>

  <app-youtube-evidence *ngIf="addingYouTubeEvidence" [manifestation]="manifestation" [hidden]="hidYouTubeEvidence"
                       (cancel)="addingYouTubeEvidence = false" (done)="hidYouTubeEvidence = true"
                       (evidence)="addedYouTubeEvidence($event)">
  </app-youtube-evidence>

  <div *ngIf="(uploadEvidence?.length === 0) && (youTubeEvidence?.length === 0) && !addingEvidence()" class="card mb-4">
    <div class="card-block row m-1">
      <h5 class="card-title col-md-12 p-1">No evidence yet</h5>
    </div>
  </div>

  <div *ngIf="!addingEvidence()">
    <app-uploadevidence-details *ngFor="let evidence of uploadEvidence; let i = index"
                                [evidence]="evidence" [evidenceIndex]="i+1">
    </app-uploadevidence-details>
    <app-youtubeevidence-details *ngFor="let evidence of youTubeEvidence; let i = index"
                                [evidence]="evidence" [evidenceIndex]="i+1">
    </app-youtubeevidence-details>
  </div>

  <h3>Reuse License NFTs</h3>

  <div class="row">
    <p class="col">License your claimed rights as <b>tradeable</b> NFTs pointing to the <b>reuse terms</b>.</p>
  </div>

  <button [disabled]="manifestation?.getStaked() === '0' || uploadEvidence.length + youTubeEvidence.length === 0"
          type="button" class="btn btn-info mb-4" (click)="mintNFT()">Mint NFT</button>

  <div class="card mb-4"
       *ngIf="manifestation?.getStaked() === '0' || uploadEvidence.length + youTubeEvidence.length === 0">
    <div class="card-block row m-1">
      <p class="card-text col-md-12 p-1 alert-warning">Not possible to mint <b>reuse license NFTs</b> for this
        manifestation, there is not CLY staked on it or at least one piece of evidence supporting authorship.</p>
    </div>
  </div>

  <div *ngIf="!addingEvidence()">
    <div class="card mb-1" *ngFor="let nft of nfts">
      <div class="card-block row m-1">
        <h5 class="card-title col-md-12 p-1 text-truncate"><img width="30px" alt="NFT" src="assets/nft.svg"/>
          <small title="{{nft?.tokenId}}">{{nft?.tokenId}}</small></h5>
        <div class="col-lg-8 p-1 mb-1">
          <h6 class="card-subtitle text-muted">Metadata</h6>
          <a class="card-text" href="https://gateway.ipfs.io/{{nft?.tokenUri?.replace('://', '/')}}" target="_blank">
            {{nft?.tokenUri}}</a>
        </div>
        <div class="col-lg-3 p-1 mb-1">
          <h6 class="card-subtitle text-muted">Registered</h6>
          <a class="card-text" href="https://goerli.etherscan.io/tx/{{nft?.transaction}}" target="_blank">
            {{nft?.creationTime | date:'medium'}}</a>
        </div>
        <div class="col-lg-1 p-1 mb-1">
          <h6 class="card-subtitle text-muted">Trade</h6>
          <a class="card-text mr-2" target="_blank"
             href="https://testnets.opensea.io/assets/0x1f9d65519a4a212ae51f525776372bca5a948293/{{nft?.tokenId}}">
            <img title="OpenSea" width="20px" src="https://testnets.opensea.io/static/images/logos/opensea.svg"/></a>
          <!-- a class="card-text mr-2" target="_blank"
             href="https://testnet.rarible.com/token/0x1f9d65519a4a212ae51f525776372bca5a948293:{{nft?.tokenId}}">
            <img title="Rarible" width="20px" src="https://miro.medium.com/max/218/1*P1W2MjnYUR329IiuFzTcGg.png"/></a-->
        </div>
      </div>
    </div>
  </div>

</div>

<div *ngIf="notFound" class="text-center">
  <button class="btn btn-info" (click)="back()">Back</button>
</div>
