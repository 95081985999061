<ul class="navbar-nav">
  <li class="nav-item">
    <span class="nav-link">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend">
          <span class="input-group-text" id="network-name">
            <span class="fab fa-ethereum"></span>
          </span>
        </div>
        <input id="no-account" name="no-account" type="text" readonly
               class="form-control" value="Connect" #no_account (focus)="no_account.blur()" (click)="connect()">
        <div class="input-group-append" >
          <button id="refresh-accounts" class="btn btn-secondary" #refresh_accounts
                  (focus)="refresh_accounts.blur()"><span class="fas fa-sync-alt"></span>
          </button>
        </div>
      </div>
    </span>
  </li>
</ul>
