<div class="row mb-3">
  <div class="col" *ngIf="manifestations && manifestations.length < 20">
    There { manifestations?.length, plural,
    =1 { is 1 authorship claim }
    other { are {{manifestations?.length}} authorship claims} } registered
  </div>
  <div class="col" *ngIf="manifestations && manifestations.length >= 20">
    Last 20 authorship claims
  </div>
  <div class="col" *ngIf="!manifestations">
    Loading...
  </div>
  <div class="col-1 fa-pull-right">
    <button id="refresh-manifestations" class="btn-sm btn-outline-dark fa-pull-right" (click)="refreshList()">
      <span class="fas fa-sync-alt"></span>
    </button>
  </div>
</div>

<div id="list" *ngIf="manifestations">

<div class="card mb-4" *ngFor="let manifestation of manifestations">
  <div class="card-block row m-1">
    <h4 class="card-title col-md-12 p-1"><span class="fas fa-gavel"></span> {{manifestation?.title}}</h4>
    <div class="col-md-6 p-1 mb-1">
      <h6 class="card-subtitle text-muted">Hash</h6>
      <a class="card-text" href="https://gateway.ipfs.io/ipfs/{{manifestation?.hash}}"
         target="_blank">{{manifestation?.hash}}</a>
    </div>
    <div class="col-md-2 p-1 mb-1">
      <h6 class="card-subtitle text-muted">Creator</h6>
      <a class="card-text" *ngFor="let author of manifestation?.authors" title="{{author}}"
         [routerLink]="['/creators', author]"
      >{{author | slice:0:6}}...{{author | slice:-4}}</a>
    </div>
    <div class="col-md-4 p-1 mb-1">
      <h6 class="card-subtitle text-muted">Registered</h6>
      <a class="card-text" href="https://goerli.etherscan.io/tx/{{manifestation?.transaction}}"
         target="_blank">{{manifestation?.creationTime | date:'medium'}}</a>
    </div>
  </div>
  <div class="card-footer">
    <a [routerLink]="['/manifestations', manifestation?.hash]" class="btn btn-info">Details</a>
  </div>
</div>

</div>
