<div class="jumbotron text-justify">
  <h2>Rhizomik Copyright<b>LY</b></h2>
  <p>Decentralized Application (ÐApp) for Copyright Management</p>
  <ngb-alert [dismissible]="false" [type]="'info'">
    <strong>Announcement:</strong> CopyrightLY is already available, explore it from the top menu!
    <div class="small">Smart contracts are deployed on <b>Goerli</b> testnet. A browser providing an
      <b>Ethereum wallet</b>, or an iOS or Android wallet application, is required. You can try
      <a href="https://metamask.io/download" target="_blank">MetaMask</a>.
    </div>
  </ngb-alert>
  <h3>Features</h3>
  <p>
    CopyrightLY is a decentralised application that leverages <b>blockchain</b> and <b>semantic web</b> technologies
    to facilitate copyright management:</p>
  <ul>
    <li>Claim <b>content authorship</b> and provide supporting <b>evidence</b>, including existing social media like YouTube videos.</li>
    <li>Token <b>curated copyright claims</b> based on CLY token staking, which is minted using a bonding curve.</li>
    <li><b>License</b> content using <b>NFTs</b>, with semantic metadata stating the <b>reuse terms</b> being transferred.</li>
    <li><b>Trade NFTs</b> on existing marketplaces (OpenSea, Rarible...), purchasers can <b>trace copyright</b> to authorship claims.</li>
  </ul>

  <h3>Scenario & Demo</h3>
  <ol>
    <li>A YouTube video creator willing to explore alternative ways to monetize content.</li>
    <li>Uploads video file and registers content hash on-chain making an authorship claim.</li>
    <li>Links authorship claim to YouTube video proofing ownership of the social media platform version.</li>
    <li>Stakes CLY token on authorship claim to show commitment on its authenticity.</li>
    <li>Mints NFT licensing the reuse terms defined in its metadata to future owners.</li>
    <li>Trades NFT on existing marketplace, where potential purchasers can trace it back to the copyright claim.</li>
  </ol>
  <div class="embed-responsive embed-responsive-16by9">
    <iframe class="embed-responsive-item" src="https://www.youtube-nocookie.com/embed/Ky8aowIP3wc" allowfullscreen></iframe>
  </div>

  <h3 class="mt-4">Implementation</h3>
  <p>
    Creators upload their content to decentralised storage and use the resulting content hash to register
    <b>on-chain authorship claims</b>, which tie content to their identities and a timestamp.
  </p>
  <p>Creators also provide <b>evidence</b> to support their claim, by uploading content or linking videos to an existing
    version they have published on YouTube. They proof ownership of the social media platform version of the video
    using an <b>oracle</b>.
  </p>
  <p>
    Creators and other stakeholders <b>curate</b> the authorship claims by <b>staking</b> the CLY token on them.
    CLY is minted using a <b>bonding curve</b>. CLY cannot be held, it is minted to be staked on an authorship claim
    and burned when un-staked.
  </p>
  <p>
    Claims and evidence can be used in case of a copyright <b>complaint</b>, even in court. This approach also
    discourages false claims, which cannot be retracted once on-chain.
  </p>
  <p>
    Content can be monetized using <b>NFTs</b> that state the <b>reuse terms</b> being transferred with the token. Terms are
    modelled using <b>semantic technologies</b> and based on the <a href="https://rhizomik.net/ontologies/copyrightonto"
    target="_blank">Copyright Ontology</a>, which provides the necessary building blocks to make these terms
    <b>machine-actionable</b>.
  </p>
  <p>
    <a href="https://github.com/rhizomik/copyrightly/wiki" target="_blank">More details...</a>
  </p>
  <h3>Team</h3>
  <a href="https://www.udl.cat" target="_blank">
    <img src="assets/UdL.svg" height="65px" alt="Universitat de Lleida"/>
  </a>
  <ul>
    <li><a href="https://rhizomik.net/~roberto" target="_blank">Roberto García</a></li>
    <li><a href="http://www.dret.udl.cat/ca/pla-formatiu/professorat/detall/index.html?enc=MjA4MjQyMTQ=" target="_blank">Ana Cediel</a></li>
    <li><a href="https://www.researchgate.net/profile/Rosa-Gil-3" target="_blank">Rosa Gil</a></li>
    <li><a href="https://www.researchgate.net/profile/Merce-Teixido" target="_blank">Mercè Teixidó</a></li>
  </ul>
  <h3>Contact</h3>
  <pre>copyrightly@rhizomik.net</pre>
  <h3>Support</h3>
  <p>
    Supported by project ONTOCHAIN, which has received funding from the European Union’s Horizon 2020 research and
    innovation programme under grant agreement No 957338
  </p>
  <a href="https://ontochain.ngi.eu" target="_blank">
    <img src="https://ontochain.ngi.eu/sites/default/files/logo-ngi-ontochain-positive.png" height="60px" alt="ONTOCHAIN Logo"/>
  </a>
  <img src="https://ontochain.ngi.eu/sites/default/files/images/EU_flag.png" height="60px" alt="European Union Flag"/>
</div>
<div>
  <p><span class="fab fa-github"></span><a href="https://github.com/rhizomik/copyrightly">
    rhizomik/copyrightly</a></p>
</div>
